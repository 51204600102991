import React, {Fragment} from 'react';

import Helmet from 'react-helmet';
import PageContentLayout from '../PageContentLayout';
import PageFooter from '../PageFooter';
import PropTypes from 'prop-types';
import cx from 'classnames';
import siteConfig from '../../../site-config';
import styled from 'styled-components';

const PageContentLayoutStyled = styled(PageContentLayout)`
  padding-top: 1.5rem;
  margin-bottom: 3rem;
`;

const { htmlTitle } = siteConfig.page;

const Page = ({
  children,
  className,
  title,
  footerAst,
}) => (
  <Fragment>
    <Helmet title={title ? `${htmlTitle} • ${title}` : htmlTitle} />
    <PageContentLayoutStyled className={cx(className)}>
      {
        children
      }
    </PageContentLayoutStyled>
    { footerAst && (
      <PageFooter contentAst={footerAst} />
    )}
  </Fragment>
);

Page.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  footerAst: PropTypes.object,
};

Page.defaultProps = {
  title: undefined,
  className: undefined,
  footerAst: undefined,
};

export default Page;
