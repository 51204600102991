import React from 'react';
import RehypeReact from 'rehype-react';

import Paragraph from '../components/Paragraph';
import PageLink from '../components/PageLink';

export default new RehypeReact({
  createElement: React.createElement,
  components: {
    p: Paragraph,
    'page-link': PageLink,
  },
}).Compiler;
