import React from 'react';
import PropTypes from 'prop-types';
import GatsbyLink from 'gatsby-link';

const PageLink = ({ to, children }) => (
  <GatsbyLink to={to}>{ children }</GatsbyLink>
);

PageLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default PageLink;
