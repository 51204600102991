import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import renderAst from '../../utils/renderAst';

import './MarkdownContent.scss';

const MarkdownContent = ({ contentAst, className }) => (
  <div className={cx('markdownContent', className)}>
    {
      renderAst(contentAst)
    }
  </div>
);

MarkdownContent.propTypes = {
  className: PropTypes.any,
  contentAst: PropTypes.object.isRequired,
};

MarkdownContent.defaultProps = {
  className: undefined,
};


export default MarkdownContent;
