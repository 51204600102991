import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';

const Content = styled.main`
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
`;

const PageContentLayout = ({ children, className }) => (
  <Content className={cx('pageContent', className)}>
    { children }
  </Content>
);

PageContentLayout.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.any,
};

PageContentLayout.defaultProps = {
  className: undefined,
};

export default PageContentLayout;
