import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import MarkdownContent from '../MarkdownContent';

import PageFooterLayout from './PageFooterLayout';

import './PageFooter.scss';

const PageFooter = ({ contentAst }) => (
  <PageFooterLayout
    body={(
      <Fragment>
        <div className="pageFooter__logo pageFooter__logo--beratung" />
        <div className="pageFooter__logo pageFooter__logo--bildungskommunikation" />
        <MarkdownContent contentAst={contentAst} className="pageFooter__info" />
      </Fragment>
    )}
    footer={(
      <Fragment>
        Made by <a href="https://www.spearwolf.de/" target="_blank" rel="noopener">spearwolf</a>
      </Fragment>
    )}
  />
);

PageFooter.propTypes = {
  contentAst: PropTypes.object.isRequired,
};

export default PageFooter;
