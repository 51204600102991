import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import './PageFooter.scss';

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const PageFooterLayout = ({ header, body, footer }) => (
  <div className="pageFooter">
    { header && (
      <div className="pageFooter__headerContainer">
        <Content className="pageFooter__header">
          { header }
        </Content>
      </div>
    )}
    <div className="pageFooter__contentContainer">
      <Content className="pageFooter__content">
        { body }
      </Content>
    </div>
    { footer && (
      <div className="pageFooter__footerContainer">
        <Content className="pageFooter__footer">
          { footer }
        </Content>
      </div>
    )}
  </div>
);

PageFooterLayout.propTypes = {
  header: PropTypes.any,
  body: PropTypes.any.isRequired,
  footer: PropTypes.any,
};

PageFooterLayout.defaultProps = {
  header: null,
  footer: null,
};

export default PageFooterLayout;
